import styled from 'styled-components';

export const Container = styled.form`
  padding: 20px;
  margin: 20px;
  min-width: 450px;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  vertical-align: center;
`;

export const ModalItemContainer = styled.div`
  padding: 10px 0;
`;
export const HiddenInput = styled.input`
  display: none;
  opacity: 0;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 20px;
  background: #f3fbfe;
  padding: 26px;
`;

export const CSVUploadContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-content: center;
  margin-bottom: 20px;
`;

export const StyledImage = styled.img`
  display: flex;
  height: 100px;
  margin-top: 20px;
  align-self: center;
`;

export const UploadButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const UploadTextContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 12px;
`;
