import {API, graphqlOperation} from 'aws-amplify';

import {
  Employee,
  GetOrganisationEmployees,
  GetOrganisationEmployeesQueryVariables,
  Pagination,
} from '../../API';
import {getOrganisationEmployees} from '../../graphql/queries';
import {
  EmployeeDTO,
  OrganisationEmployeeData,
  StatusEnum,
} from './Employees.interface';

export const createEmployeeDto = (employee: Employee): EmployeeDTO => {
  return {
    name: employee.firstName,
    surname: employee.lastName,
    employeeId: employee?.externalEmployeeId || undefined,
    email: employee?.employeeEmail,
    customerId: employee?.customerProfileId || undefined,
    onboardingCode: employee?.onboardingCode || undefined,
    organisationTeamId: employee?.organisationTeamId || undefined,
    team: employee?.team || undefined,
    status: employee?.customerProfileId
      ? StatusEnum.ACTIVE
      : StatusEnum.PENDING,
    createdAt: employee.createdAt || undefined,
    updatedAt: employee.updatedAt || undefined,
  };
};

const getEmployeesOrganisation = async (
  input: GetOrganisationEmployeesQueryVariables,
): Promise<any> => {
  try {
    const result = await API.graphql<OrganisationEmployeeData>(
      graphqlOperation(getOrganisationEmployees, input),
    );
    // @ts-ignore
    if (result.data?.getOrganisationEmployees?.employees) {
      // @ts-ignore
      return {
        // @ts-ignore
        employees: result.data.getOrganisationEmployees.employees.map(
          (employee: Employee) => createEmployeeDto(employee),
        ),
        // @ts-ignore
        pagination: result?.data?.getOrganisationEmployees?.pagination,
      };
    } else {
      throw new Error('No employees found for the specified input.');
    }
  } catch (err) {
    console.error('error fetching employees information', err);
    throw new Error('Unable to retrieve employees information');
  }
};

export {getEmployeesOrganisation};
