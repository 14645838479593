import {
  Button,
  ErrorComponent,
  Modal,
  Text,
} from '@ekko-earth/dashboard-components';
import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {toast} from 'react-hot-toast';

import Loader from '../../../components/Loader';
import {
  fetchPreSignedUrl,
  processUploadedFile,
  putFileOnS3Bucket,
} from '../UploadPage.api';
import csvImage from './csv-example.png';
import {
  Container,
  CSVUploadContainer,
  HiddenInput,
  StyledImage,
  TextContainer,
  TitleContainer,
  UploadButtonWrapper,
  UploadTextContainer,
} from './UploadModal.styles';

interface UploadCsvModalProps {
  visible: boolean;
  onOpenChange: (value: boolean) => void;
  onSubmit: () => void;
}

function UploadCsvModal({
  visible,
  onOpenChange,
  onSubmit,
}: UploadCsvModalProps) {
  const [file, setFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [processFile, setProcessFile] = useState<boolean>(false); // this is to call the api
  const [uploadFile, setUploadFile] = useState<boolean>(false); // this is to upload the file to the bucket
  const [showProcessButton, setShowProcessButton] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [preSignedUrl, setPreSignedUrl] = useState(null);
  const [error, setError] = useState<null | string>(null);
  const csvImage = require('./csv-example.png');

  //Reset the state when the modal is closed
  useEffect(() => {
    if (!visible) {
      setFile(null);
      setShowProcessButton(false);
      setPreSignedUrl(null);
      setUploadFile(false);
      setProcessFile(false);
    }
  }, [visible]);

  useEffect(() => {
    if (!file) {
      setShowProcessButton(false);
    }
  }, [file]);

  useEffect(() => {
    if (file) {
      const {name} = file;
      setLoading(true);
      fetchPreSignedUrl(name)
        .then((url) => {
          setPreSignedUrl(url);
          setShowProcessButton(true);
        })
        .catch(({message}) => {
          setError(message);
        })
        .finally(() => setLoading(false));
    }
  }, [file]);

  useEffect(() => {
    if (uploadFile && preSignedUrl && file) {
      //so now we do a put to the api
      setLoading(true);
      putFileOnS3Bucket(preSignedUrl, file)
        .then((res) => {
          setProcessFile(true);
        })
        .catch((err) => {
          console.error('error', err);
          setError('Failed to upload file');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [preSignedUrl, file, uploadFile]);

  useEffect(() => {
    if (processFile && file) {
      //this is where we need to activate the button to process
      setLoading(true);
      const {name} = file;
      processUploadedFile(name)
        .then((res) => {
          onSubmit();
          toast.success('File uploaded successfully!');
        })
        .catch(({message}) => {
          setError(message);
        })
        .finally(() => setLoading(false));
    }
  }, [file, processFile]);
  const handleUploadFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: {files},
    } = event;
    const file = files?.[0] || null;
    setFile(file);
  };
  const handleProcessFileClick = () => setUploadFile(true);
  const handleOnClick = () => {
    setFile(null);
    inputRef.current?.click();
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 8000);
    }
  }, [error]);

  const fileName = file ? file.name : 'No file chosen';

  return (
    <Modal isOpen={visible} onOpenChange={onOpenChange}>
      <Container>
        <TitleContainer>
          <Text variant="secondary" fontSize={'28'}>
            CSV Upload
          </Text>
        </TitleContainer>
        <TextContainer>
          <Text variant="primary" fontSize={'16'}>
            1. Upload a csv file containing a list of all employees in your
            company using the columns specificed below.
          </Text>
          <Text variant="primary" fontSize={'16'}>
            2. An email will be sent to each employee inviting them to join the
            ekko app as part of your organisation.
          </Text>
          <Text variant="primary" fontSize={'16'}>
            3. They will be able to use an onboarding code to signup and enjoy
            the ekko app for free.
          </Text>
        </TextContainer>

        <CSVUploadContainer>
          <StyledImage src={csvImage} alt="csv" />
          <UploadTextContainer>
            <Text fontSize="18px">
              Choose File:{' '}
              <Text fontSize={'22px'} variant="secondary">
                {fileName}
              </Text>
            </Text>
          </UploadTextContainer>
          <UploadButtonWrapper>
            <Button
              label={showProcessButton ? 'Choose different file' : 'Upload'}
              variant={showProcessButton ? 'secondary' : 'primary'}
              size={'large'}
              onClick={handleOnClick}
            />
            <HiddenInput
              ref={inputRef}
              type="file"
              name="file"
              onChange={handleUploadFileChange}
              accept=".csv"
            />
            {showProcessButton && (
              <Button
                label={'Process CSV'}
                variant="primary"
                size="large"
                onClick={handleProcessFileClick}
              />
            )}
          </UploadButtonWrapper>
        </CSVUploadContainer>
        {error && (
          <ErrorComponent
            position={'top-center'}
            message={error}
            duration={8000}
          />
        )}
        {loading && <Loader />}
      </Container>
    </Modal>
  );
}

export default UploadCsvModal;
