import {Spinner} from '@ekko-earth/dashboard-components';
import React from 'react';

import {Container, InnerContainer} from './Loader.styles';

const Loader = () => {
  return (
    <Container>
      <InnerContainer>
        <Spinner size={'md'} color={'primary'} />
      </InnerContainer>
    </Container>
  );
};

export default Loader;
