import {API, graphqlOperation} from 'aws-amplify';

import {QueryFilter} from '../../../../../API';
import {getCarbonImpact, getTotalRewards} from '../../../../../graphql/queries';
import {
  EcoRewardsResponse,
  EquivalencyCardResponse,
} from '../../EcoRewards/Graphs/Graphs.interface';

const fetchEcoRewards = async (
  input: QueryFilter,
  rewardType: 'Trees' | 'Bottles',
): Promise<EcoRewardsResponse> => {
  try {
    // @ts-ignore
    const {data} = await API.graphql(
      graphqlOperation(getTotalRewards, {input}),
    );

    const ecoRewardsData = data.getTotalRewards;

    if (
      ecoRewardsData &&
      Object.hasOwn(ecoRewardsData, 'errors') &&
      ecoRewardsData.errors.length
    ) {
      throw new Error('unable to retrieve eco rewards');
    }

    return {
      data: ecoRewardsData.data,
      totalTrees: ecoRewardsData?.totalTrees,
      totalBottles: ecoRewardsData?.totalBottles,
    };
  } catch (err) {
    console.error(`error fetching ${rewardType} ecoReward information`, err);
    throw new Error(`Unable to load ${rewardType} ecoReward data.`);
  }
};

const getEquivalencyCards = async (
  input: QueryFilter,
): Promise<EquivalencyCardResponse> => {
  try {
    // @ts-ignore
    const {data} = await API.graphql(
      graphqlOperation(getCarbonImpact, {input}),
    );

    return data.getCarbonImpact.carbonImpactData.carbonEquivalance;
  } catch (e) {
    console.log('error fetching equivalency cards', e);
    throw new Error('Unable to get equivalency cards.');
  }
};

export {fetchEcoRewards, getEquivalencyCards};
