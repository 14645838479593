import {Link} from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.section`
  max-width: 500px;
  width: 100%;
  h1,
  h3 {
    margin: 0;
  }
`;
const SubHeading = styled.h3`
  margin: 0;
  display: flex;
  justify-content: center;
`;
const InnerContainer = styled.form`
  display: grid;
  grid-row-gap: 24px;
  margin-top: 64px;
  position: relative;
`;
const ForgotContainer = styled.p`
  text-align: center;
  a {
    cursor: pointer;
  }
`;
const StyledLink = styled(Link)``;

export {Container, InnerContainer, SubHeading, ForgotContainer, StyledLink};
