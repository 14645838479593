import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: form;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export {Container, FormContainer};
