import {
  ErrorComponent,
  FooterLogo,
  LoginImg,
} from '@ekko-earth/dashboard-components';
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {login} from '../../assets/images';
import AuthForm from '../../components/AuthForm';
import {useAuthProvider} from '../../context/Auth/Auth.context';
import {LoginInterface} from './Login.interface';
import {BrandImg, Container, ImageContainer} from './Login.styles';

const Login = ({formType}: LoginInterface) => {
  const {user, brand, loading, error} = useAuthProvider();
  let navigate = useNavigate();
  let {logo = '', name = '', showOnLoginPage = false} = brand || {};
  useEffect(() => {
    // if (user && Object.hasOwn(user, 'info') && brand && formType === 'signIn') {
    if (
      !loading &&
      user &&
      Object.hasOwn(user, 'info') &&
      brand &&
      formType === 'signIn'
    ) {
      navigate('/dashboard');
    }
  }, [loading, user, brand]);

  return (
    <Container>
      <ImageContainer>
        <LoginImg image={login} />
      </ImageContainer>
      {showOnLoginPage && <BrandImg src={logo} alt={`${name} logo`} />}
      <AuthForm formType={formType} loading={loading} />
      {error && (
        <ErrorComponent
          position={'top-center'}
          message={error}
          duration={3000}
        />
      )}
      <FooterLogo text={'Powered by ekko'} />
    </Container>
  );
};

export default Login;
