import {FooterLogo, Text} from '@ekko-earth/dashboard-components';
import React from 'react';

import {Container, FooterText, StyledLink} from './Footer.style';

const Footer = () => {
  return (
    <>
      <Container>
        <FooterLogo text={'Powered by ekko'}></FooterLogo>
      </Container>
      <FooterText>
        <Text> &copy; 2023 ekko. All rights reserved.</Text>
        <Text>
          <StyledLink
            href="https://ekko.earth/privacy-policy/"
            target="_blank"
            rel="noreferrer">
            Privacy Policy
          </StyledLink>
          &nbsp;&nbsp;&nbsp;
          <StyledLink
            href="https://ekko.earth/terms-and-conditions/"
            target="_blank"
            rel="noreferrer">
            Terms and Conditions
          </StyledLink>
        </Text>
      </FooterText>
    </>
  );
};

export default Footer;
