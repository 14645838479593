import {API, graphqlOperation} from 'aws-amplify';

import {
  getEmployerByCognitoId,
  getOrganisationBrandingByCognitoId,
} from '../../graphql/queries';

const fetchEmployerByCognitoId = async () => {
  try {
    const {
      // @ts-ignore
      data: {
        getEmployerByCognitoId: {employer},
      },
    } = await API.graphql(graphqlOperation(getEmployerByCognitoId));
    return employer;
  } catch (err) {
    console.error('error fetching employer information', err);
    throw new Error('Unable to retrieve user information');
  }
};
const fetchOrganisationBrandingByCognitoId = async () => {
  try {
    const {
      // @ts-ignore
      data: {getOrganisationBrandingByCognitoId: branding},
    } = await API.graphql(graphqlOperation(getOrganisationBrandingByCognitoId));
    return branding;
  } catch (e) {
    console.error('error fetching organisation branding information', e);
    throw new Error('Unable to retrieve organisation brand information');
  }
};

export {fetchEmployerByCognitoId, fetchOrganisationBrandingByCognitoId};
