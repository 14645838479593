import {
  Dropdown,
  Filter as FilterComponent,
} from '@ekko-earth/dashboard-components';
import {SelectOption} from '@ekko-earth/dashboard-components/dist/cjs/types/components/Select/Select.interface';
import React, {useEffect, useState} from 'react';

import {useFilterProvider} from '../../context/Filter/Filter.context';
import {fetchOptions} from './Filter.api';
import {FilterInterface} from './Filter.interface';
import {Container, FilterComponentContainer} from './Filter.styles';

const Filter = () => {
  const {filters, handleFilterChange} = useFilterProvider();
  const [options, setOptions] = useState<SelectOption[] | undefined>(undefined);
  const [applyFilters, setApplyFilters] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<FilterInterface | null>(
    null,
  );
  const [placeholder, setPlaceholder] = useState<string>('All');
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  useEffect(() => {
    // we are separating this as the props from the filter component differ from the auth context
    if (filters) {
      const newFilters: any = {...filters};
      newFilters['option'] = newFilters['teamIds'];
      delete newFilters['teamIds'];
      setCurrentFilters(newFilters as unknown as FilterInterface);
      //todo set the apply to true
    }
  }, [filters]);

  useEffect(() => {
    if (applyFilters && currentFilters) {
      setApplyFilters(false);
      const newFilters: any = {
        teamIds:
          currentFilters['option'][0] === 'all' ? [] : currentFilters['option'],
        ...currentFilters,
      };
      delete newFilters.option;
      handleFilterChange(newFilters);
    }
  }, [applyFilters, filters, currentFilters]);

  useEffect(() => {
    fetchOptions()
      .then((res) => {
        setOptions([{label: 'All', value: 'all'}, ...res.options]);
      })
      .catch((error) => console.error({error}));
  }, []);

  useEffect(() => {
    if (filters && options) {
      const selectedOption = options.filter(
        ({value}) => value === filters.teamIds[0],
      );
      if (!selectedOption.length) {
        setPlaceholder('All');
      } else {
        // this will come into play later on in life
        setPlaceholder(selectedOption[0].label as string);
      }
    }
  }, [filters, options]);

  const handleChange = (e: any) => {
    setCurrentFilters(e);
    setApplyFilters(true);
    setIsFilterOpen(false);
  };

  const handleOpenChange = (state: any) => {
    setIsFilterOpen(state);
  };

  return (
    currentFilters && (
      <Container>
        <Dropdown
          label={'Filter'}
          stopDropdownPropagation={true}
          isDropdownOpen={isFilterOpen}
          handleDropdownOpen={handleOpenChange}>
          <FilterComponentContainer>
            <FilterComponent
              label={'Teams'}
              filterState={currentFilters}
              handleChange={handleChange}
              placeholder={placeholder}
              selectOptions={options}
            />
          </FilterComponentContainer>
        </Dropdown>
      </Container>
    )
  );
};

export default Filter;
