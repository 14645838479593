import {Text} from '@ekko-earth/dashboard-components';
import {createColumnHelper} from '@tanstack/react-table';
import React, {ReactElement} from 'react';

import {
  EmployeeDTO,
  StatusEnum,
} from '../../pages/Employees/Employees.interface';
import {OnboardingContainer} from '../../pages/Employees/Employees.styles';

/**
 * helper to sort out column
 */
const columnHelper = createColumnHelper<
  EmployeeDTO & {options: ReactElement}
>();

/**
 * Helper Components
 */
interface OnboardingStatusProps {
  status: StatusEnum;
}
const Status = ({status}: OnboardingStatusProps) => {
  switch (status) {
    case StatusEnum.ACTIVE:
      return (
        <Text variant="success" fontSize="16">
          {' '}
          ACTIVE{' '}
        </Text>
      );
    case StatusEnum.PENDING:
      return (
        <Text variant="warning" fontSize="16">
          {' '}
          PENDING{' '}
        </Text>
      );
    default:
      return null;
  }
};

const StatusContainer = ({status}: OnboardingStatusProps) => (
  <OnboardingContainer>
    <Status status={status} />
  </OnboardingContainer>
);

const columns = [
  columnHelper.accessor('name', {
    header: () => 'First Name',
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor('surname', {
    header: () => 'Last Name',
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor('email', {
    header: () => 'Email',
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor('employeeId', {
    header: () => 'Employee ID',
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor('team', {
    header: () => 'Team',
    cell: (info) => info.getValue()?.name || '',
    footer: (info) => info.column.id,
    enableSorting: false,
  }),
  columnHelper.accessor('status', {
    header: () => 'Status',
    cell: (info) => <StatusContainer status={info.getValue()} />,
    footer: (info) => info.column.id,
    enableSorting: false,
  }),
];
export default columns;
