import {Button, Input, Text} from '@ekko-earth/dashboard-components';
import React, {useEffect, useState} from 'react';

import {useAuthProvider} from '../../context/Auth/Auth.context';
import {
  Container,
  InnerContainer,
  LinkContainer,
  StyledLink,
  SubHeading,
} from './ResetPassword.styles';

const ResetPassword = () => {
  const {formState, handleFormChange, forgotPasswordSubmit} = useAuthProvider();
  const [disableForm, setDisableForm] = useState(true);
  const {confirmationCode, password} = formState;
  const newPassword = password;

  useEffect(() => {
    if (confirmationCode && newPassword) {
      const isValid = confirmationCode.length > 0 && newPassword.length > 0;
      setDisableForm(!isValid);
    }
  }, [confirmationCode, newPassword]);

  return (
    <Container>
      <h1>
        <Text
          variant={'secondary'}
          fontWeight={'bold'}
          fontSize={'32'}
          align={'center'}>
          Reset your password
        </Text>
      </h1>
      <SubHeading>
        <Text align={'center'}>Check your email for a confirmation code.</Text>
      </SubHeading>
      <InnerContainer>
        <div>
          <Input
            type={'text'}
            placeholder={'Confirmation code'}
            name={'confirmationCode'}
            label={'Confirmation Code'}
            value={confirmationCode}
            handleChange={handleFormChange}
          />
        </div>
        <div>
          <Input
            name="password"
            label="New Password"
            type="password"
            value={password}
            handleChange={handleFormChange}
          />
        </div>
        <Button
          variant={'primary'}
          label={'Reset Password'}
          size={'large'}
          onClick={forgotPasswordSubmit}
          disabled={disableForm}
        />
        <LinkContainer>
          <Text>Remember your password? </Text>
          <StyledLink to="/">
            <Text variant={'ternary'}>Click here</Text>
          </StyledLink>
        </LinkContainer>
      </InnerContainer>
    </Container>
  );
};

export default ResetPassword;
