/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTeam = /* GraphQL */ `
  mutation CreateTeam($input: CreateTeamInput) {
    createTeam(input: $input) {
      team {
        id
        name
        description
        parentTeamId
        organisationProfileId
        onboardingPageId
        employees
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam($input: UpdateTeamInput) {
    updateTeam(input: $input) {
      team {
        id
        name
        description
        parentTeamId
        organisationProfileId
        onboardingPageId
        employees
        createdAt
        updatedAt
      }
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee($input: CreateEmployeeInput) {
    createEmployee(input: $input) {
      employee {
        id
        firstName
        lastName
        externalEmployeeId
        employeeEmail
        customerProfileId
        organisationProfileId
        onboardingCode
        organisationTeamId
        team {
          id
          name
          description
          parentTeamId
          organisationProfileId
          onboardingPageId
          employees
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const removeEmployee = /* GraphQL */ `
  mutation RemoveEmployee($input: RemoveEmployeeInput) {
    removeEmployee(input: $input) {
      errors {
        ... on Error {
          description
          message
          path
        }
        ... on NotFoundError {
          message
          path
          resource
        }
        ... on ValidationError {
          message
          path
          validation
        }
        ... on UnauthorizedError {
          message
          path
          resource
        }
      }
    }
  }
`;
export const processFileUpload = /* GraphQL */ `
  mutation ProcessFileUpload($input: GetFileUploadURL) {
    processFileUpload(input: $input) {
      rows
      employees
      teams
      sentEmails
      errors {
        ... on Error {
          description
          message
          path
        }
        ... on NotFoundError {
          message
          path
          resource
        }
        ... on ValidationError {
          message
          path
          validation
        }
        ... on UnauthorizedError {
          message
          path
          resource
        }
      }
    }
  }
`;
