import {Auth} from 'aws-amplify';
import React, {
  ChangeEvent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {
  fetchEmployerByCognitoId,
  fetchOrganisationBrandingByCognitoId,
} from './Auth.api';
import {
  AuthContextInterface,
  AuthInterface,
  FormState,
  FormStateTypes,
} from './Auth.interface';

const AuthContext = createContext<AuthInterface>({} as AuthInterface);

const AuthProvider = ({children}: AuthContextInterface): JSX.Element => {
  // Auth Form Controls
  const [formState, updateFormState] = useState<FormState>({
    email: '',
    password: '',
    confirmationCode: '',
  });

  // User and Auth
  const [user, updateUser] = useState<any>(null);
  const [brand, updateBrand] = useState(null);

  // Additional State management
  const [error, setError] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();

  const checkAuthState = useCallback(
    async (route: string) => {
      try {
        const currentStatus = await Auth.currentAuthenticatedUser();
        return currentStatus;
      } catch (err) {
        navigate(route);
      }
    },
    [navigate],
  );

  // if no user exists we should check that a user exists;
  useEffect(() => {
    const getLoggedInUser = async () => {
      return await Auth.currentAuthenticatedUser();
    };
    const authPaths = ['/forgot-password', '/reset-password'];
    const {pathname} = location;
    if (!user && !authPaths.includes(pathname)) {
      setLoading(true);
      getLoggedInUser()
        .then(({attributes}) => {
          updateUser(attributes);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          navigate('/');
        });
    }
  }, [user, location]);

  // reset the auth errors after they are set
  useEffect(() => {
    const sleep = async (ms: number) =>
      await new Promise((resolve) => setTimeout(resolve, ms));
    if (error) {
      sleep(3100).then(() => {
        setError(null);
      });
    }
  }, [error]);

  //if the user exists we need to get the extra info for the employee
  useEffect(() => {
    if (user && !Object.hasOwn(user, 'info')) {
      setLoading(true);
      fetchEmployerByCognitoId()
        .then((info) => {
          const updatedUserInfo = {...user, info};
          updateUser(updatedUserInfo);
        })
        .catch((err) => {
          setLoading(false);
          console.error('error', err);
        });
    }
  }, [user]);

  // we need to get the brand information
  useEffect(() => {
    if (user && Object.hasOwn(user, 'info') && !brand) {
      setLoading(true);
      fetchOrganisationBrandingByCognitoId()
        .then(({branding}) => {
          updateBrand(branding);
        })
        .catch((err) => {
          console.log('err', err);
          setLoading(false);
        });
    }
  }, [user, brand]);

  //set the loading states
  useEffect(() => {
    if (user && Object.hasOwn(user, 'info') && brand) {
      setLoading(false);
    }
  }, [loading, user, brand]);

  const updateForm = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: {name, value},
      } = event;
      const newFormState = {
        ...formState,
        [name]: value,
      };
      updateFormState(newFormState);
    },
    [formState],
  );

  const logout = useCallback(async (route?: string) => {
    try {
      console.log('clicked logout');
      await Auth.signOut().then(() => {
        updateUser(null);
      });
    } catch (err) {
      setError('unable to log out.');
      console.log('error signing out: ', err);
    }
  }, []);

  const login = useCallback(async () => {
    const {email, password} = formState;
    setLoading(true);
    try {
      const user = await Auth.signIn(email, password);
      const userInfo = {username: user.username, ...user.attributes};
      updateUser(userInfo);
    } catch (err) {
      setError('Unable to login. Please check email and password');
      console.log('Error logging in: ', err);
      setLoading(false);
    }
  }, [formState]);

  const handleFormChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.persist();
      updateForm(e);
    },
    [updateForm],
  );

  const validateEmail = useCallback(() => {
    const {email} = formState;
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return regex.test(email);
  }, [formState]);

  const forgotPassword = useCallback(async () => {
    try {
      const {email} = formState;
      await Auth.forgotPassword(email);
      navigate('/reset-password');
    } catch (err) {
      setError('Unable to reset password');
      console.log('error submitting username to reset password...', err);
    }
  }, [formState, navigate]);

  const forgotPasswordSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const {email, confirmationCode, password} = formState;
      await Auth.forgotPasswordSubmit(email, confirmationCode, password);
      navigate('/');
    } catch (e) {
      setError('Unable to reset password');
      console.log('error updating password: ', e);
    }
    setLoading(false);
  }, [formState, navigate]);

  const signUp = useCallback(async () => {
    setLoading(true);
    try {
      const {email, password} = formState;
      await Auth.signUp({
        username: email,
        password,
        attributes: {email},
      });
      //todo this might change due to implementation of verification email
      navigate('/confirm-signup');
    } catch (e) {
      setError('Failed to sign up.');
      console.log('error signing up: ', e);
    }
    setLoading(false);
  }, [formState, navigate]);

  const confirmSignUp = useCallback(async () => {
    setLoading(true);
    try {
      const {email, confirmationCode} = formState;
      await Auth.confirmSignUp(email, confirmationCode);
      navigate('/');
    } catch (e) {
      setError('Unable to finalise signup.');
      console.log('error signing up:', e);
    }
    setLoading(false);
  }, [formState, navigate]);

  const getAuthFormValue = useCallback(
    (prop: FormStateTypes) => {
      return formState[prop];
    },
    [formState, navigate],
  );

  const memo = useMemo(
    () => ({
      formState,
      user,
      error,
      brand,
      loading,
      checkAuthState,
      login,
      logout,
      handleFormChange,
      forgotPassword,
      forgotPasswordSubmit,
      signUp,
      confirmSignUp,
      getAuthFormValue,
      validateEmail,
    }),
    [
      user,
      brand,
      loading,
      error,
      formState,
      checkAuthState,
      login,
      logout,
      handleFormChange,
      forgotPassword,
      forgotPasswordSubmit,
      signUp,
      confirmSignUp,
      getAuthFormValue,
    ],
  );
  return (
    <AuthContext.Provider value={memo}>{children}</AuthContext.Provider>
  ) as JSX.Element;
};

const useAuthProvider = () => useContext(AuthContext);

export {AuthProvider, useAuthProvider};
export default AuthContext;
