import {Table, Text} from '@ekko-earth/dashboard-components';
import React from 'react';

import Loader from '../Loader';
import columns from './EmployeeTable.Helpers';
import {EmployeeTableInterface} from './EmployeeTable.interface';
import {EmptyTableContainer} from './EmployeeTable.styles';

const EmployeeTable = ({
  loading,
  employees,
  pagination,
  handlePageSize,
  handleCurrentPage,
  toggleSorting,
}: EmployeeTableInterface) => {
  return (
    <>
      {loading && <Loader />}
      {!loading && !employees.length && (
        <EmptyTableContainer>
          <Text variant="primary" fontSize="16">
            No employees found. Please upload a file containing all of your
            employee's details.
          </Text>
        </EmptyTableContainer>
      )}
      {!loading && employees.length > 0 && pagination && (
        <Table
          tableData={employees}
          columns={columns}
          paginationData={pagination}
          fetchData={(pageData) => {
            handleCurrentPage(pageData.pageIndex);
            handlePageSize(pageData.pageSize);
          }}
          toggleSorting={toggleSorting}
        />
      )}
    </>
  );
};

export default EmployeeTable;
