/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrganisationTeams = /* GraphQL */ `
  query GetOrganisationTeams {
    getOrganisationTeams {
      teams {
        id
        name
        organisationProfileId
        employees
        createdAt
        updatedAt
      }
    }
  }
`;
export const getOrganisationEmployees = /* GraphQL */ `
  query GetOrganisationEmployees(
    $input: PaginationInput
    $sort: EmployeeSortInput
  ) {
    getOrganisationEmployees(input: $input, sort: $sort) {
      pagination {
        limit
        currentPage
        pageCount
        totalRecords
      }
      employees {
        id
        firstName
        lastName
        externalEmployeeId
        employeeEmail
        customerProfileId
        organisationProfileId
        onboardingCode
        organisationTeamId
        team {
          id
          name
          organisationProfileId
          employees
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const getEmployeeAdoptionRate = /* GraphQL */ `
  query GetEmployeeAdoptionRate($input: QueryFilter) {
    getEmployeeAdoptionRate(input: $input) {
      adoptionRate {
        totalUploadedEmployees
        activeEmployees
        maxAllowedEmployees
        percentage
      }
      errors {
        ... on Error {
          description
          message
          path
        }
        ... on NotFoundError {
          message
          path
          resource
        }
        ... on ValidationError {
          message
          path
          validation
        }
        ... on UnauthorizedError {
          message
          path
          resource
        }
      }
    }
  }
`;
export const getEmployerByCognitoId = /* GraphQL */ `
  query GetEmployerByCognitoId {
    getEmployerByCognitoId {
      employer {
        id
        firstName
        lastName
        organisationProfileId
        email
        cognitoId
        role
        createdAt
        updatedAt
      }
    }
  }
`;
export const getOrganisationBrandingByCognitoId = /* GraphQL */ `
  query GetOrganisationBrandingByCognitoId {
    getOrganisationBrandingByCognitoId {
      branding {
        preferredName
        logo
        logoSmall
        displayLogoOnLogin
      }
    }
  }
`;
export const getOrganisationBrandingById = /* GraphQL */ `
  query GetOrganisationBrandingById($inout: GetOrganisationBrandingInput) {
    getOrganisationBrandingById(inout: $inout) {
      branding {
        preferredName
        logo
        logoSmall
        displayLogoOnLogin
      }
    }
  }
`;
export const getFileUploadURL = /* GraphQL */ `
  query GetFileUploadURL($input: GetFileUploadURL) {
    getFileUploadURL(input: $input) {
      url
    }
  }
`;
export const getTotalRewards = /* GraphQL */ `
  query GetTotalRewards($input: QueryFilter) {
    getTotalRewards(input: $input) {
      totalTrees
      totalBottles
      data {
        month
        trees
        bottles
      }
      errors {
        ... on Error {
          description
          message
          path
        }
        ... on NotFoundError {
          message
          path
          resource
        }
        ... on ValidationError {
          message
          path
          validation
        }
        ... on UnauthorizedError {
          message
          path
          resource
        }
      }
    }
  }
`;
export const getCarbonImpact = /* GraphQL */ `
  query GetCarbonImpact($input: QueryFilter) {
    getCarbonImpact(input: $input) {
      carbonImpactData {
        data {
          date
          value
        }
        total
        carbonEquivalance {
          phonesCharged
          milesByCar
        }
      }
      errors {
        ... on Error {
          description
          message
          path
        }
        ... on NotFoundError {
          message
          path
          resource
        }
        ... on ValidationError {
          message
          path
          validation
        }
        ... on UnauthorizedError {
          message
          path
          resource
        }
      }
    }
  }
`;
export const getCarbonImpactCategories = /* GraphQL */ `
  query GetCarbonImpactCategories($input: QueryFilter) {
    getCarbonImpactCategories(input: $input) {
      carbonCategoriesImpactData {
        data {
          travel
          groceries
          lifestyle
          shopping
          other
        }
        total
      }
      errors {
        ... on Error {
          description
          message
          path
        }
        ... on NotFoundError {
          message
          path
          resource
        }
        ... on ValidationError {
          message
          path
          validation
        }
        ... on UnauthorizedError {
          message
          path
          resource
        }
      }
    }
  }
`;
