import {BarGraph} from '@ekko-earth/dashboard-components';
import moment from 'moment';
import React, {useEffect, useState} from 'react';

import {useFilterProvider} from '../../../../../context/Filter/Filter.context';
import Loader from '../../../../Loader';
import NoData from '../../../../NoData';
import {CardContentWrapper} from '../../../DashboardSection.styles';
import {fetchEcoRewards} from './Graphs.api';
import {EcoRewardsResponse} from './Graphs.interface';

type RewardInterface = {
  rewardType: 'Bottles' | 'Trees';
};

const Rewards = ({rewardType}: RewardInterface) => {
  const {filters} = useFilterProvider();
  const [loading, setLoading] = useState(true);
  const [rewards, setRewards] = useState<EcoRewardsResponse | null>(null);
  const [error, setErrors] = useState(null);

  useEffect(() => {
    if (loading) {
      fetchEcoRewards(
        {
          ...filters,
          startDate: moment(
            moment().subtract(6, 'month').startOf('month').toDate(),
          ).format('yyyy-MM-DD'),
          endDate: moment(moment().endOf('day').toDate()).format('yyyy-MM-DD'),
        },
        rewardType,
      )
        .then((res) => {
          setRewards(res);
        })
        .catch((err) => setErrors(err))
        .finally(() => setLoading(false));
    }
  }, [loading, filters, setLoading, setRewards, setErrors]);

  useEffect(() => {
    if (filters) {
      setLoading(true);
      setErrors(null);
      setRewards(null);
    }
  }, [filters, setLoading, setRewards, setErrors]);
  return (
    <>
      <CardContentWrapper height={220} width={330}>
        {!loading && rewards && rewards.data.length > 0 && (
          <BarGraph
            data={rewards.data.map((item, i) => {
              return {
                date: moment().month(item?.month)?.format('yyyy/MM/DD'),
                value: item[rewardType.toLowerCase() as 'bottles' | 'trees'],
              };
            })}
            header={rewardType}
            subHeader={`${
              rewards[`total${rewardType as 'Bottles' | 'Trees'}`]
            } Total`}
          />
        )}
        {!loading && (!rewards || !rewards.data.length) && (
          <NoData componentName={`${rewardType} Collected yet.`} />
        )}
        {loading && <Loader />}
      </CardContentWrapper>
    </>
  );
};

export default Rewards;
