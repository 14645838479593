import React, {createContext, useContext, useState} from 'react';

import {
  FilterContextInterface,
  FilterInterface,
  FilterProps,
} from './Filter.interface';

const FilterContext = createContext<FilterInterface>({} as FilterInterface);

const initialFilters: FilterProps = {
  teamIds: [],
  startDate: '2023-01-01',
  endDate: '',
};
const FilterProvider = ({children}: FilterContextInterface): JSX.Element => {
  const [filters, setFilters] = useState<FilterProps>(initialFilters);

  function handleFilterChange(newFilters: any) {
    setFilters({...newFilters});
  }

  return (
    <FilterContext.Provider value={{filters, handleFilterChange}}>
      {children}
    </FilterContext.Provider>
  ) as JSX.Element;
};
const useFilterProvider = () => useContext(FilterContext);
export {FilterProvider, useFilterProvider};

export default FilterContext;
