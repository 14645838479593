import styled from 'styled-components';

const Container = styled.section`
  padding: 30px;
`;

const FooterText = styled.p`
  background-color: #f9fdff;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 16px 0px;
`;

const StyledLink = styled.a`
  &:link,
  &:visited {
    color: #7678a2;
    text-decoration: none;
  }
`;

export {Container, FooterText, StyledLink};
