import {useState} from 'react';

import DashboardSection from '../../components/DashboardSection';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import {FilterProvider} from '../../context/Filter/Filter.context';
import {GraphProvider} from '../../context/Graph/Graph.Context';
import {Container} from './Dashboard.styles';

const Dashboard = () => {
  const [sections] = useState([
    'banner',
    'carbonInput',
    'ecoRewards',
    'resources',
  ]);
  return (
    <FilterProvider>
      <Header />
      <Container>
        <GraphProvider>
          {sections.map((section) => (
            <DashboardSection key={section} section={section} />
          ))}
        </GraphProvider>
      </Container>
      <Footer />
    </FilterProvider>
  );
};

export default Dashboard;
