import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

const InnerContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export {Container, InnerContainer};
