import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 30px;
  column-gap: 30px;
  padding: 10px;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const CardContent = styled.div`
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 0;
  position: relative;
`;

const SummaryCardsContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 10px;
  padding-bottom: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const DashboardRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
`;

export {
  Container,
  FlexContainer,
  CardContent,
  Wrapper,
  SummaryCardsContainer,
  DashboardRowContainer,
};
