import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import './App.css';
import ProtectedRoute from './components/ProtectedRoute';
import Public from './components/Public';
import {AuthProvider, useAuthProvider} from './context/Auth/Auth.context';
import Dashboard from './pages/Dashboard';
import Employees from './pages/Employees';
import Login from './pages/Login';

const queryClient = new QueryClient();

function App() {
  console.log('env', process.env.REACT_APP_AWS_OAUTH_DOMAIN);
  const {user} = useAuthProvider();

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<Login formType={'signIn'} />} />
              <Route
                path="forgot-password"
                element={<Login formType={'forgot-password'} />}
              />
              <Route
                path="reset-password"
                element={<Login formType={'reset-password'} />}
              />
              <Route element={<ProtectedRoute user={user} />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="employees" element={<Employees />} />
              </Route>
              {/*todo this needs to change to the resource page and moved under a protected route*/}
              {/* <Route path="resources" element={<Public />} /> */}
              {/*todo we need to add the 404 pages here*/}
              {/* <Route element={<Public />} /> */}
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
