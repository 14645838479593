import {Button, Input, Text} from '@ekko-earth/dashboard-components';
import React, {useEffect, useState} from 'react';

import {useAuthProvider} from '../../context/Auth/Auth.context';
import {
  Container,
  ForgotContainer,
  InnerContainer,
  StyledLink,
  SubHeading,
} from './SignIn.styles';

const SignIn = () => {
  const [disableForm, setDisableForm] = useState(true);
  const {login, handleFormChange, formState, validateEmail} = useAuthProvider();
  const {email, password} = formState;

  useEffect(() => {
    if (password && email) {
      const validForm = validateEmail() && password.length > 6;
      setDisableForm(!validForm);
    }
  }, [email, password, validateEmail]);

  return (
    <Container>
      <h1>
        <Text
          variant={'secondary'}
          fontWeight={'bold'}
          fontSize={'32'}
          align={'center'}>
          Welcome to positive change
        </Text>
      </h1>
      <InnerContainer>
        <div>
          <Input
            name="email"
            placeholder="example@ekko.earth"
            label="Your Email"
            type="email"
            value={email}
            handleChange={handleFormChange}
          />
        </div>
        <div>
          <Input
            name="password"
            label="Password"
            type="password"
            value={password}
            handleChange={handleFormChange}
          />
        </div>
        <Button
          variant={'primary'}
          label={'Login'}
          size={'large'}
          onClick={login}
          disabled={disableForm}
        />
      </InnerContainer>
      <ForgotContainer>
        <Text>Forgot your password? </Text>
        <StyledLink to="/forgot-password">
          <Text variant={'ternary'}>Click here</Text>
        </StyledLink>
      </ForgotContainer>
    </Container>
  );
};

export default SignIn;
