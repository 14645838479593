import {Text, View} from '@ekko-earth/dashboard-components';
import React from 'react';

import {useAuthProvider} from '../../../../context/Auth/Auth.context';
import Filter from '../../../Filter';
import {Container, Greeting} from './Banner.styles';

const Banner = () => {
  const {
    user: {
      info: {firstName},
    },
  } = useAuthProvider();
  //we need to trigger the filter change
  return (
    <View>
      <Container>
        <Greeting>
          <Text variant="secondary" fontSize="24" fontWeight="bold">
            Hello, {firstName}
          </Text>
        </Greeting>
        <Filter />
      </Container>
    </View>
  );
};

export default Banner;
