import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  justify-content: space-between;
`;

const Greeting = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export {Container, Greeting};
