import {GetOrganisationEmployees} from '../../API';

export interface TeamPageProps {
  teamsData: Team[];
}

export type Team = {
  id: string;
  // The name of the team
  name: string;
  // The description of the team
  description: string;
  // the id of the parent team
  parentTeamId: string;
  // the id of the organisation
  organisationProfileId: string;
  // a unique uuid representing the teamId
  onboardingPageId: string;
  // a list of employees in the team
  employees?: Array<string> | null;
  // Date of creation
  createdAt?: string | null;
  // Date of last modification
  updatedAt?: string | null;
};

export type EmployeeDTO = {
  name: string;
  surname: string;
  employeeId?: string;
  email: string;
  customerId?: string;
  onboardingCode?: string;
  organisationTeamId?: string;
  team?: Team;
  status: StatusEnum;
  createdAt?: string;
  updatedAt?: string;
};

export enum StatusEnum {
  ACTIVE = 'active',
  PENDING = 'pending',
}

export type OrganisationEmployeeData = {
  data: {getAccountDetails: GetOrganisationEmployees};
};
