import {API, graphqlOperation} from 'aws-amplify';

import {GetFileUploadURLQueryVariables} from '../../API';
import {processFileUpload} from '../../graphql/mutations';
import {getFileUploadURL} from '../../graphql/queries';

const fetchPreSignedUrl = async (fileName: string) => {
  try {
    const input = {
      input: {
        fileName,
      },
    } as GetFileUploadURLQueryVariables;
    const {
      // @ts-ignore
      data: {
        getFileUploadURL: {url},
      },
    } = await API.graphql(graphqlOperation(getFileUploadURL, input));
    return url;
  } catch (e) {
    console.log('error fetching url', e);
    throw new Error('Something went wrong preparing your document.');
  }
};

const putFileOnS3Bucket = async (preSignedUrl: string, file: File) => {
  const headers = new Headers({'Content-type': 'application/csv'});
  return await fetch(preSignedUrl, {
    method: 'PUT',
    headers,
    body: file,
  });
};
const processUploadedFile = async (fileName: string) => {
  try {
    const input = {
      input: {
        fileName,
      },
    } as GetFileUploadURLQueryVariables;
    const {
      // @ts-ignore
      data,
    } = await API.graphql(graphqlOperation(processFileUpload, input));
    return data;
  } catch (err) {
    console.log('error processing file', err);
    throw new Error('Error processing file');
  }
};

export {fetchPreSignedUrl, putFileOnS3Bucket, processUploadedFile};
