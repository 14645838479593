import styled from 'styled-components';

const Container = styled.section``;

const FilterComponentContainer = styled.div`
  max-width: 500px;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  position: absolute;
  left: -750px;
  background: white;
`;

export {Container, FilterComponentContainer};
