import {LineGraph} from '@ekko-earth/dashboard-components';
import React, {useEffect, useState} from 'react';

import {useFilterProvider} from '../../../../../context/Filter/Filter.context';
import Loader from '../../../../Loader';
import NoData from '../../../../NoData';
import {CardContentWrapper} from '../../../DashboardSection.styles';
import {fetchCarbonImpact} from './Graph.api';
import {CarbonFootprintResponse} from './Graph.interface';

const CarbonFootprint = () => {
  const [carbonFootprint, setCarbonFootprint] =
    useState<CarbonFootprintResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {filters} = useFilterProvider();

  useEffect(() => {
    if (loading && filters) {
      fetchCarbonImpact(filters)
        .then((carbonImpactData) => setCarbonFootprint(carbonImpactData))
        .catch((e) => setError(e))
        .finally(() => setLoading(false));
    }
  }, [loading, setLoading, filters, setCarbonFootprint, setError]);

  useEffect(() => {
    if (filters) {
      setLoading(true);
      setError(null);
      setCarbonFootprint(null);
    }
  }, [filters, setLoading, setCarbonFootprint, setError]);

  return (
    <>
      <CardContentWrapper height={250} width={420}>
        {!loading && carbonFootprint && carbonFootprint.data.length > 0 && (
          <LineGraph
            data={[carbonFootprint.data]}
            header={`${carbonFootprint.total}`}
            subHeader={'CO2/Kg'}
          />
        )}
        {!loading && (!carbonFootprint || !carbonFootprint.data.length) && (
          <NoData componentName={'Carbon Footprint data.'} />
        )}
        {loading && <Loader />}
      </CardContentWrapper>
    </>
  );
};

export default CarbonFootprint;
