import {LineGraph} from '@ekko-earth/dashboard-components';
import {useEffect, useState} from 'react';

import {useFilterProvider} from '../../../../../context/Filter/Filter.context';
import Loader from '../../../../Loader';
import NoData from '../../../../NoData';
import {CardContentWrapper} from '../../../DashboardSection.styles';
import {fetchCarbonImpact} from './Graph.api';
import {CarbonFootprintResponse} from './Graph.interface';

const CarbonOffset = () => {
  const [carbonOffset, setCarbonOffset] =
    useState<CarbonFootprintResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {filters} = useFilterProvider();

  useEffect(() => {
    if (loading) {
      setLoading(true);
      setCarbonOffset(null);
      fetchCarbonImpact(filters)
        .then((data) => setCarbonOffset(data))
        .catch((e) => setError(e))
        .finally(() => setLoading(false));
    }
  }, [loading, setLoading, filters, setCarbonOffset, setError]);

  useEffect(() => {
    if (filters) {
      setLoading(true);
      setError(null);
      setCarbonOffset(null);
    }
  }, [filters, setLoading, setError, setCarbonOffset]);

  return (
    <>
      <CardContentWrapper height={250} width={420}>
        {!loading && carbonOffset && carbonOffset.data.length > 0 && (
          <LineGraph
            data={[carbonOffset.data]}
            header={`${carbonOffset.total}`}
            subHeader={'CO2/Kg'}
          />
        )}
        {!loading && (!carbonOffset || !carbonOffset.data.length) && (
          <NoData componentName={'Carbon Offset data.'} />
        )}
        {loading && <Loader />}
      </CardContentWrapper>
    </>
  );
};

export default CarbonOffset;
