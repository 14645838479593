import {Card, CardHeader, View} from '@ekko-earth/dashboard-components';

import {CardContentWrapper} from '../../DashboardSection.styles';
import {
  CardContent,
  Container,
  FlexContainer,
  Wrapper,
} from './EcoRewards.styles';
import {Equivalency} from './EquivalencyCard';
import {Rewards} from './Graphs';

const EcoRewards = () => {
  return (
    <Wrapper>
      <Container>
        <FlexContainer>
          <Card hasShadow>
            <CardHeader heading={'Bottles'} />
            <CardContent>
              <CardContentWrapper height={220} width={330}>
                <Rewards rewardType={'Bottles'} />
              </CardContentWrapper>
            </CardContent>
          </Card>
        </FlexContainer>
        <FlexContainer>
          <Card hasShadow>
            <CardHeader heading={'Trees'} />
            <CardContent>
              <CardContentWrapper height={220} width={330}>
                <Rewards rewardType={'Trees'} />
              </CardContentWrapper>
            </CardContent>
          </Card>
        </FlexContainer>
        <FlexContainer>
          <Card hasShadow>
            <CardHeader heading={'CO2 category breakdown'} />
            <CardContentWrapper height={220} width={330}>
              <Equivalency />
            </CardContentWrapper>
          </Card>
        </FlexContainer>
      </Container>
    </Wrapper>
  );
};
export default EcoRewards;
