import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';

import {
  getCarbonImpact,
  getCarbonImpactCategories,
  getTotalRewards,
} from '../../graphql/queries';
import {FilterProps} from '../Filter/Filter.interface';
import {
  CarbonFootprintData,
  GetCarbonFootprintResponse,
} from './Graph.Interface';

const fetchCarbonFootprint = async (
  input: FilterProps,
): Promise<{
  data: CarbonFootprintData[];
  total: number;
  carbonEquivalance: {milesByCar: number; phonesCharged: number};
}> => {
  try {
    const response = (await API.graphql(
      graphqlOperation(getCarbonImpact, {input}),
    )) as GraphQLResult<GetCarbonFootprintResponse>;

    if (response?.errors && response?.errors.length > 0) {
      throw new Error(response.errors[0]?.message);
    }

    const {
      data = [],
      total = 0,
      carbonEquivalance = {milesByCar: 0, phonesCharged: 0},
    } = response?.data?.getCarbonImpact?.carbonImpactData || {};

    return {data, total, carbonEquivalance};
  } catch (e) {
    console.log(e);
    throw new Error('Unable to retrieve Carbon footprint data.');
  }
};

const fetchCarbonBreakdown = async (input: FilterProps) => {
  try {
    const {
      // @ts-ignore
      data: {
        getCarbonImpactCategories: {
          carbonCategoriesImpactData: {data, total},
        },
      },
    } = await API.graphql(graphqlOperation(getCarbonImpactCategories, {input}));

    return {data, total};
  } catch (e) {
    console.error(e);
    throw new Error('Unable to find Carbon Breakdown data.');
  }
};

const fetchRewards = async (input: FilterProps) => {
  try {
    const {
      // @ts-ignore
      data: {
        getTotalRewards: {data, totalTrees, totalBottles},
      },
    } = await API.graphql(graphqlOperation(getTotalRewards, {input}));

    return {
      data: {
        data,
        totalTrees,
        totalBottles,
      },
    };
  } catch (e) {
    console.error(e);
    throw new Error('Unable to find Rewards data.');
  }
};

export {fetchCarbonFootprint, fetchCarbonBreakdown, fetchRewards};
