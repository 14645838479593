import styled from 'styled-components';

const EmptyTableContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 50px;
`;

export {EmptyTableContainer};
