import {Card, CardHeader, ChartHeader} from '@ekko-earth/dashboard-components';

import {SkeletonLoader} from '../SkeletonLoader';
import {SummaryCardProps} from './SummaryCard.interface';
import {SummaryCardContent, SummaryIconContainer} from './SummaryCard.styles';

export default function SummaryCard({
  heading,
  value,
  icon,
  unit,
  loading = true,
}: SummaryCardProps) {
  return (
    <Card hasShadow>
      {loading ? (
        <SkeletonLoader height="115px" />
      ) : (
        <>
          <CardHeader heading={heading} />
          <SummaryCardContent>
            <SummaryIconContainer>{icon}</SummaryIconContainer>
            <ChartHeader header={value} subHeader={unit} />
          </SummaryCardContent>
        </>
      )}
    </Card>
  );
}
