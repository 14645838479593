import styled from 'styled-components';

const Container = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 163px 1fr 105px;
  grid-template-areas: 'img header' 'img form' 'img footer';
  width: 100%;
  height: 100vh;
`;

const FormContainer = styled.section`
  grid-area: form;
`;

const ImageContainer = styled.section`
  grid-area: img;
`;

const BrandImg = styled.img`
  max-width: 200px;
  display: flex;
  justify-self: center;
  align-self: center;
`;

export {Container, FormContainer, ImageContainer, BrandImg};
