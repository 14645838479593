import styled from 'styled-components';

export const SummaryCardContent = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  margin-bottom: 15px;
`;

export const SummaryIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #f4fbfe;
  border-radius: 10px;
  margin-left: 15px;
  margin-right: 15px;
`;
