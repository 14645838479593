import {API, graphqlOperation} from 'aws-amplify';

import {QueryFilter} from '../../../../../API';
import {
  getCarbonImpact,
  getCarbonImpactCategories,
  getEmployeeAdoptionRate,
} from '../../../../../graphql/queries';
import {
  AdoptionRateResponse,
  CarbonCategoryResponse,
  CarbonFootprintResponse,
} from './Graph.interface';

const fetchCarbonImpact = async (
  input: QueryFilter,
): Promise<CarbonFootprintResponse> => {
  try {
    // @ts-ignore
    const {data} = await API.graphql(
      graphqlOperation(getCarbonImpact, {input}),
    );

    const {carbonImpactData} = data.getCarbonImpact;
    if (
      carbonImpactData &&
      Object.hasOwn(carbonImpactData, 'errors') &&
      carbonImpactData.errors.length
    ) {
      throw new Error('unable to retrieve carbon data');
    }

    return {
      data: carbonImpactData.data,
      total: carbonImpactData?.total,
    };
  } catch (err) {
    console.error('error fetching employer information', err);
    throw new Error('Unable to load data.');
  }
};
const fetchCarbonCategoryBreakdown = async (
  input: QueryFilter,
): Promise<CarbonCategoryResponse> => {
  try {
    // @ts-ignore
    const {data} = await API.graphql(
      graphqlOperation(getCarbonImpactCategories, {input}),
    );
    const {carbonCategoriesImpactData} = data.getCarbonImpactCategories;
    return {...carbonCategoriesImpactData};
  } catch (err) {
    console.error('error fetching employer information', err);
    throw new Error('Unable to load Carbon Category Breakdown.');
  }
};

const fetchEmployeeAdoptionRate = async (
  input: QueryFilter,
): Promise<AdoptionRateResponse> => {
  try {
    // @ts-ignore
    const {data} = await API.graphql(
      graphqlOperation(getEmployeeAdoptionRate, {input}),
    );

    const {adoptionRate} = data.getEmployeeAdoptionRate;

    if (
      adoptionRate &&
      Object.hasOwn(adoptionRate, 'errors') &&
      adoptionRate.errors.length
    ) {
      throw new Error('unable to adoption rate data');
    }
    return adoptionRate as unknown as AdoptionRateResponse;
  } catch (err) {
    console.error('error fetching adoption rate information', err);
    throw new Error('Unable to load adoption rate data.');
  }
};

export {
  fetchCarbonImpact,
  fetchCarbonCategoryBreakdown,
  fetchEmployeeAdoptionRate,
};
