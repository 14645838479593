import React from 'react';

import ForgotPassword from '../ForgotPassword';
import Loader from '../Loader';
import ResetPassword from '../ResetPassword';
import SignIn from '../SignIn';
import {AuthFormInterface} from './AuthForm.interface';
import {Container, FormContainer} from './AuthForm.styles';

const AuthForm = ({formType, loading}: AuthFormInterface) => {
  const renderForm = () => {
    switch (formType) {
      case 'signIn':
        return <SignIn key={formType} />;
      case 'forgot-password':
        return <ForgotPassword key={formType} />;
      case 'reset-password':
        return <ResetPassword key={formType} />;
      default:
        return null;
    }
  };
  return (
    <Container>
      <FormContainer>
        {loading && <Loader />}
        {renderForm()}
      </FormContainer>
    </Container>
  );
};

export default AuthForm;
