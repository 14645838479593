import {FooterLogo} from '@ekko-earth/dashboard-components';
import React from 'react';

import {NoDataProps} from './NoData.interface';

const NoData = ({componentName}: NoDataProps) => {
  return <FooterLogo text={`No ${componentName}`} />;
};

export default NoData;
