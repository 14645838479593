import {ProgressGraph} from '@ekko-earth/dashboard-components';
import React, {useEffect, useState} from 'react';

import {useFilterProvider} from '../../../../../context/Filter/Filter.context';
import Loader from '../../../../Loader';
import NoData from '../../../../NoData';
import {CardContentWrapper} from '../../../DashboardSection.styles';
import {fetchEmployeeAdoptionRate} from './Graph.api';
import {AdoptionRateResponse} from './Graph.interface';

const AdoptionRate = () => {
  const {filters} = useFilterProvider();
  const [adoptionRate, setAdoptionRate] = useState<AdoptionRateResponse | null>(
    null,
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (loading) {
      fetchEmployeeAdoptionRate(filters)
        .then((adoptionRateData) => setAdoptionRate(adoptionRateData))
        .catch((e) => setError(e))
        .finally(() => setLoading(false));
    }
  }, [loading, setLoading, setError, setAdoptionRate]);

  useEffect(() => {
    if (filters) {
      setLoading(true);
      setError(null);
      setAdoptionRate(null);
    }
  }, [filters, setLoading, setError, setAdoptionRate]);

  return (
    <>
      <CardContentWrapper height={250} width={375}>
        {!loading && adoptionRate && (
          <ProgressGraph
            value={adoptionRate.activeEmployees}
            total={adoptionRate.maxAllowedEmployees}
          />
        )}
        {!loading && !adoptionRate && (
          <NoData componentName={'Adoption Rate data.'} />
        )}
        {loading && <Loader />}
      </CardContentWrapper>
    </>
  );
};

export default AdoptionRate;
