import {Button, Card, Text, View} from '@ekko-earth/dashboard-components';
import {useQuery} from '@tanstack/react-query';
import {createColumnHelper, SortingState} from '@tanstack/react-table';
import {ReactElement, useEffect, useState} from 'react';

import {EmployeeSortInput} from '../../API';
import EmployeeTable from '../../components/EmployeeTable';
import {EmployeePaginationInterface} from '../../components/EmployeeTable/EmployeeTable.interface';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import UploadCsvModal from '../UploadPage/modal/UploadCsvModal';
import {getEmployeesOrganisation} from './Employees.api';
import {
  ButtonContainer,
  Container,
  FooterContainer,
  HeaderContainer,
  StyledToaster,
  TeamsContainer,
  TextContainer,
} from './Employees.styles';

function Employees() {
  const [modalVisibilty, setModalVisibility] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [sortingState, setSortingState] = useState<SortingState>([]);

  const {data, isLoading, refetch} = useQuery({
    queryKey: ['employees'],
    queryFn: () =>
      getEmployeesOrganisation({
        input: {limit: pageSize, page: currentPage},
        sort: mapSortingStateToEmployeeSortInput(sortingState),
      }),
    refetchOnWindowFocus: false,
  });

  const mapSortingStateToEmployeeSortInput = (
    sortingState: SortingState,
  ): EmployeeSortInput => {
    const sort: EmployeeSortInput = {};

    const mapDirection = (desc: boolean) => {
      return desc ? -1 : 1;
    };

    sortingState.forEach((columnSort) => {
      switch (columnSort.id) {
        case 'name':
          sort.firstName = mapDirection(columnSort.desc);
          break;
        case 'surname':
          sort.lastName = mapDirection(columnSort.desc);
          break;
        case 'email':
          sort.employeeEmail = mapDirection(columnSort.desc);
          break;
        case 'employeeId':
          sort.externalEmployeeId = mapDirection(columnSort.desc);
          break;
      }
    });

    return sort;
  };

  useEffect(() => {
    refetch();
  }, [currentPage, pageSize, sortingState]);

  const submitFile = () => {
    setTimeout(() => {
      refetch();
    }, 2000);
    setModalVisibility(false);
  };
  // todo @tom we might want to move this into a state :thinking:
  const pagination = {
    pageCount: data?.pagination?.pageCount || 0,
    pageIndex: currentPage,
    pageSize,
  } as EmployeePaginationInterface;

  return (
    <>
      <Header />
      <StyledToaster
        toastOptions={{style: {fontFamily: 'poppins', fontSize: '16px'}}}
      />
      <View>
        <TeamsContainer>
          <Card>
            <HeaderContainer>
              <TextContainer>
                <Text fontSize="24" variant="secondary" fontWeight="bold">
                  Employees
                </Text>
                <Text fontSize="16" variant="primary">
                  Upload a file containing all of your employee's details
                </Text>
              </TextContainer>
              <ButtonContainer>
                <Button
                  variant="primary"
                  label={'Upload CSV'}
                  onClick={() => setModalVisibility(true)}></Button>
              </ButtonContainer>
              <UploadCsvModal
                visible={modalVisibilty}
                onOpenChange={setModalVisibility}
                onSubmit={submitFile}></UploadCsvModal>
            </HeaderContainer>
          </Card>
          <EmployeeTable
            loading={isLoading}
            employees={data?.employees || []}
            pagination={pagination}
            handlePageSize={setPageSize}
            handleCurrentPage={setCurrentPage}
            toggleSorting={setSortingState}
          />
        </TeamsContainer>
        <FooterContainer>
          <Footer />
        </FooterContainer>
      </View>
    </>
  );
}

export default Employees;
