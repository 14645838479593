import {Link} from 'react-router-dom';
import styled from 'styled-components';

const Nav = styled.nav`
  width: 100%;
  display: grid;
  grid-template-columns: 210px 1fr min-content;
  grid-template-rows: 48px;
  grid-template-areas: 'brand . nav';
`;

const HomeLink = styled(Link)`
  grid-area: brand;
  display: flex;
  max-width: 210px;
`;
const Brand = styled.img`
  height: 100%;
  padding-left: 20px;
`;

const NavList = styled.ul`
  padding: 0;
  margin: 0;
  grid-area: nav;
  list-style: none;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 180px;
  align-items: center;
`;

const NavItem = styled.li`
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  text-decoration: none;
`;

const ModalContainer = styled.form`
  padding: 20px;
  margin: 20px;
  min-width: 450px;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  vertical-align: center;
`;

const EmailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 10px;
  border-radius: 16px;
  background-color: #f9fdff;
  margin-top: 20px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export {
  Brand,
  HomeLink,
  Nav,
  NavList,
  NavItem,
  StyledLink,
  ModalContainer,
  EmailContainer,
  TextContainer,
};
