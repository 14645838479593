import {
  BottleIcon,
  Card,
  CardHeader,
  PlaneIcon,
  RefreshIcon,
  TreeIcon,
  View,
} from '@ekko-earth/dashboard-components';
import React from 'react';

import {useGraphProvider} from '../../../../context/Graph/Graph.Context';
import {SummaryCard} from '../../../SummaryCard';
import {
  CardContent,
  Container,
  DashboardRowContainer,
  FlexContainer,
  SummaryCardsContainer,
  Wrapper,
} from './CarbonInput.styles';
import {
  AdoptionRate,
  CarbonCategory,
  CarbonFootprint,
  CarbonOffset,
} from './Graphs';

const CarbonInput = () => {
  const {carbonFootprint, rewardData} = useGraphProvider();

  return (
    <View>
      <SummaryCardsContainer>
        <SummaryCard
          heading="Carbon Footprint"
          icon={<PlaneIcon />}
          value={`${Number(carbonFootprint?.total || 0).toLocaleString()}`}
          loading={carbonFootprint?.loading}
          unit="CO2/Kg"></SummaryCard>
        <SummaryCard
          heading="Carbon Offset"
          icon={<RefreshIcon />}
          value={`${Number(carbonFootprint?.total || 0).toLocaleString()}`}
          loading={carbonFootprint?.loading}
          unit="CO2/Kg"></SummaryCard>
        <SummaryCard
          heading="Bottles"
          icon={<BottleIcon />}
          value={`${Number(
            rewardData?.data?.totalBottles || 0,
          ).toLocaleString()}`}
          loading={rewardData?.loading}
          unit="Collected"></SummaryCard>
        <SummaryCard
          heading="Trees"
          icon={<TreeIcon />}
          value={`${Number(
            rewardData?.data?.totalTrees || 0,
          ).toLocaleString()}`}
          loading={rewardData?.loading}
          unit="Planted"></SummaryCard>
      </SummaryCardsContainer>
      <Wrapper>
        <Container>
          <DashboardRowContainer>
            <FlexContainer
              key={'COFootprint'}
              style={{gridArea: 'COFootprint'}}>
              <Card hasShadow>
                <CardHeader heading={'Total Carbon Footprint'} />
                <CardContent>
                  <CarbonFootprint />
                </CardContent>
              </Card>
            </FlexContainer>
            <FlexContainer
              key={'footprintDonut'}
              style={{gridArea: 'footprintDonut'}}>
              <Card hasShadow>
                <CardHeader heading={'Carbon Categories'} />
                <CardContent>
                  <CarbonCategory />
                </CardContent>
              </Card>
            </FlexContainer>
          </DashboardRowContainer>
          <DashboardRowContainer>
            <FlexContainer key={'COOffset'} style={{gridArea: 'COOffset'}}>
              <Card hasShadow>
                <CardHeader heading={'Total Carbon Offset'} />
                <CardContent>
                  <CarbonOffset />
                </CardContent>
              </Card>
            </FlexContainer>
            <FlexContainer
              key={'adoptionRate'}
              style={{gridArea: 'adoptionRate'}}>
              <Card hasShadow>
                <CardHeader heading={'Adoption Rate'} />
                <CardContent>
                  <AdoptionRate />
                </CardContent>
              </Card>
            </FlexContainer>
          </DashboardRowContainer>
        </Container>
      </Wrapper>
    </View>
  );
};

export default CarbonInput;
