import React from 'react';

import {Banner, CarbonInput, EcoRewards} from './sections';

const DashboardSection = ({section, ...rest}: any) => {
  switch (section) {
    case 'banner':
      return <Banner {...rest} />;
    case 'carbonInput':
      return <CarbonInput {...rest} />;
    case 'ecoRewards':
      return <EcoRewards />;
    case 'resources':
    default:
      return null;
  }
};

export default DashboardSection;
