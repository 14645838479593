/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten. But we do it anyway :)
import {Auth} from 'aws-amplify';

const getIdToken = async () => {
  try {
    const session = await Auth.currentSession();
    if (session.isValid()) {
      return {Authorization: session.getIdToken().getJwtToken()};
    } else {
      return undefined;
    }
  } catch (error) {
    console.log('User auth session undefined');
    return undefined;
  }
};
const awsmobile = {
  aws_project_region: 'eu-west-2',
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: 'eu-west-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_AWS_OAUTH_DOMAIN,
  },
  graphql_headers: getIdToken,
};

export default awsmobile;
