import {
  Dropdown,
  LifeSaverIcon,
  List,
  ListItem,
  Modal,
  PowerIcon,
  Text,
  ToastComponent,
  Tooltip,
  UserProfile,
  View,
} from '@ekko-earth/dashboard-components';
import {useState} from 'react';

import {useAuthProvider} from '../../context/Auth/Auth.context';
import {StyledCopyIcon} from '../../pages/Employees/Employees.styles';
import {
  Brand,
  EmailContainer,
  HomeLink,
  ModalContainer,
  Nav,
  NavItem,
  NavList,
  StyledLink,
  TextContainer,
} from './Header.styles';

const navLinks = [{name: 'Employees', to: '/employees'}];
const Header = () => {
  const {
    logout,
    user: {
      info: {firstName, lastName},
    },
    brand: {logo, preferredName},
  } = useAuthProvider();
  const [isEmailToastVisible, setEmailToastVisible] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const brandAlt = `brand logo for ${preferredName}`;
  const email = 'incentives@ekko.earth';

  const successToast = () => {
    return (
      <ToastComponent position={'top-center'} duration={3000}>
        {'Email copied to clipboard'}
      </ToastComponent>
    );
  };

  const emailModal = () => {
    return (
      <Modal isOpen={isModalVisible} onOpenChange={setModalVisible}>
        <ModalContainer>
          <TextContainer>
            <Text fontSize={'28'} variant="secondary">
              Contact Us
            </Text>
            <Text fontSize={'16'}>
              Need to get in touch? Contact us using this email and we’ll get
              back to you as soon as possible!
            </Text>
          </TextContainer>
          <EmailContainer>
            <Text fontSize={'20'} variant="secondary">
              {email}
            </Text>
            <Tooltip content="Copy Email">
              <span
                onClick={() => {
                  successToast();
                  navigator.clipboard.writeText(email);
                }}>
                <StyledCopyIcon size={20} />
              </span>
            </Tooltip>
          </EmailContainer>
        </ModalContainer>
      </Modal>
    );
  };
  return (
    <View background={'#FFFFFF'}>
      <>
        <Nav role={'navigation'}>
          <HomeLink to={'/dashboard'}>
            <Brand src={logo} alt={brandAlt}></Brand>
          </HomeLink>
          <NavList>
            {navLinks.map(({name, to}) => (
              <NavItem key={name}>
                <StyledLink to={to}>
                  {' '}
                  <Text>{name}</Text>
                </StyledLink>
              </NavItem>
            ))}
            <NavItem>
              <Dropdown
                label={
                  <UserProfile firstname={firstName} lastname={lastName} />
                }>
                <List>
                  <ListItem onClick={() => logout('/')}>
                    <PowerIcon /> <Text>Logout</Text>
                  </ListItem>
                  <ListItem onClick={() => setModalVisible(true)}>
                    <LifeSaverIcon /> <Text>Contact Support</Text>
                  </ListItem>
                </List>
              </Dropdown>
            </NavItem>
          </NavList>
        </Nav>
      </>

      <>{isModalVisible && emailModal()}</>
    </View>
  );
};

export default Header;
