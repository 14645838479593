import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function SkeletonLoader({height}: {height?: string}) {
  return (
    <div style={{width: '100%', height: '100%', lineHeight: 1}}>
      <Skeleton
        height={height || '100%'}
        width={'100%'}
        style={{width: '100%', height: '100%'}}
        baseColor="#F6F6F6"
        highlightColor="#FDFDFD"
      />
    </div>
  );
}
