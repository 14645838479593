import React, {createContext, useContext, useEffect, useState} from 'react';

import {useFilterProvider} from '../Filter/Filter.context';
import {
  fetchCarbonBreakdown,
  fetchCarbonFootprint,
  fetchRewards,
} from './Graph.Api';
import {
  GraphContextInterface,
  GraphInterface,
  GraphStateKeys,
  SummaryKey,
} from './Graph.Interface';

//todo this must have a list of the graphs that have data nad loading props
const GraphContext = createContext<GraphInterface>({} as GraphInterface);

const GraphProvider = ({children}: GraphContextInterface) => {
  const [graphs, setGraphs] = useState<GraphInterface>({} as GraphInterface);
  const {filters} = useFilterProvider();

  const handleStateDataUpdate = (
    stateKey: GraphStateKeys,
    data: any,
    total?: number | null,
  ) => {
    setGraphs((prevState: any) => ({
      ...prevState,
      [stateKey]: {
        ...prevState[stateKey],
        total,
        data,
      },
    }));
  };
  const handleSummaryUpdate = (stateKey: SummaryKey, value: number) => {
    setGraphs((prevState: any) => ({
      ...prevState,
      summaries: {
        ...prevState.summaries,
        [stateKey]: value,
      },
    }));
  };
  const toggleStateLoading = (stateKey: GraphStateKeys, loading: boolean) => {
    setGraphs((prevState: any) => ({
      ...prevState,
      [stateKey]: {
        ...prevState[stateKey],
        loading,
      },
    }));
  };

  //carbonFootprint
  useEffect(() => {
    toggleStateLoading('carbonFootprint', true);
    if (filters) {
      fetchCarbonFootprint(filters)
        .then(({total, data, carbonEquivalence}: any) => {
          handleSummaryUpdate('carbonFootprint', total);
          handleStateDataUpdate('carbonFootprint', data, total);
          handleStateDataUpdate('carbonEquivalency', carbonEquivalence);
        })
        .catch()
        .finally(() => toggleStateLoading('carbonFootprint', false));
    }
  }, [filters]);

  //carbonBreakdown
  useEffect(() => {
    toggleStateLoading('carbonBreakdown', true);
    if (filters) {
      fetchCarbonBreakdown(filters)
        .then(({total, data}: any) => {
          handleStateDataUpdate('carbonBreakdown', data, total);
        })
        .catch()
        .finally(() => toggleStateLoading('carbonBreakdown', false));
    }
  }, [filters]);

  //ecoRewards:

  useEffect(() => {
    toggleStateLoading('rewardData', true);
    if (filters) {
      fetchRewards(filters)
        .then(({data}: any) => {
          handleStateDataUpdate('rewardData', data);
        })
        .catch()
        .finally(() => toggleStateLoading('rewardData', false));
    }
  }, [filters]);

  return (
    <GraphContext.Provider value={graphs}>{children}</GraphContext.Provider>
  );
};

const useGraphProvider = () => useContext(GraphContext);

export {GraphProvider, useGraphProvider};

export default GraphContext;
