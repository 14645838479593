import {CopyIcon} from '@ekko-earth/dashboard-components';
import {Toaster} from 'react-hot-toast';
import styled from 'styled-components';

export const Container = styled.main`
  margin: auto 100px;
  justify-content: center;
  min-height: 100vh;
`;

export const TeamsContainer = styled.div`
  margin-top: 50px;
  position: relative;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 50px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div``;

export const StyledToaster = styled(Toaster)``;

export const StyledCopyIcon = styled(CopyIcon)``;

export const CustomTrigger = styled.span`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 5px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 0px;
`;

export const OnboardingContainer = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: relative;
`;

export const OnboardingIcons = styled.div`
  padding-left: 20px;
  align-items: center;
  display: flex;
  position: relative;
`;

export const FooterContainer = styled.div`
  align-items: center;
  padding-top: 15%;
`;
