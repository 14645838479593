import {Button, Input, Text} from '@ekko-earth/dashboard-components';
import React, {useEffect, useState} from 'react';

import {useAuthProvider} from '../../context/Auth/Auth.context';
import {
  Container,
  InnerContainer,
  LinkContainer,
  StyledLink,
  SubHeading,
} from './ForgotPassword.styles';

const ForgotPassword = () => {
  const {handleFormChange, forgotPassword, formState, validateEmail} =
    useAuthProvider();
  const [disableForm, setDisableForm] = useState(true);
  const {email} = formState;

  useEffect(() => {
    if (email) {
      setDisableForm(!validateEmail());
    }
  }, [email, validateEmail]);

  return (
    <Container>
      <h1>
        <Text
          variant={'secondary'}
          fontWeight={'bold'}
          fontSize={'32'}
          align={'center'}>
          Reset your password
        </Text>
      </h1>
      <InnerContainer>
        <div>
          <Input
            name="email"
            placeholder="example@ekko.earth"
            label="Your Email"
            type="email"
            value={email}
            handleChange={handleFormChange}
          />
        </div>
        <Button
          variant={'primary'}
          label={'Request Reset'}
          size={'large'}
          onClick={forgotPassword}
          disabled={disableForm}
        />
        <LinkContainer>
          <Text>Remember your password? </Text>
          <StyledLink to="/">
            <Text variant={'ternary'}>Click here</Text>
          </StyledLink>
        </LinkContainer>
      </InnerContainer>
    </Container>
  );
};

export default ForgotPassword;
