import {EquivalencyCard} from '@ekko-earth/dashboard-components';
import React, {useEffect, useState} from 'react';

import {car, phone} from '../../../../../assets/images';
import {useFilterProvider} from '../../../../../context/Filter/Filter.context';
import Loader from '../../../../Loader';
import NoData from '../../../../NoData';
import {getEquivalencyCards} from '../Graphs/Graphs.api';
import {EquivalencyCardResponse} from '../Graphs/Graphs.interface';
import {
  Container,
  NoDataContainer,
  VerticalLine,
} from './EquivalencyCard.styles';

const Equivalency = () => {
  const {filters} = useFilterProvider();
  const [loading, setLoading] = useState(true);
  const [equivalencyCards, setEquivalencyCards] =
    useState<EquivalencyCardResponse>();
  const [error, setErrors] = useState(null);

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    if (loading) {
      getEquivalencyCards(filters)
        .then((response) => {
          setEquivalencyCards(response);
        })
        .catch((err) => setErrors(err))
        .finally(() => setLoading(false));
    }
  }, [filters, loading, setLoading]);

  /**
   * todo @Sibo / @anyOtherDev
   * we need to make the last few modifications to the Container wrt styling
   *   I currently do not have data coming back so I cant test this accordingly
   *     - Have a great day
   */
  return (
    <>
      {equivalencyCards && (
        <Container>
          <EquivalencyCard
            title={'Miles by car'}
            count={equivalencyCards.milesByCar}
            image={car as any}
          />
          <VerticalLine />
          <EquivalencyCard
            title={'Phones Charged'}
            count={equivalencyCards.phonesCharged}
            image={phone as any}
          />
        </Container>
      )}
      {!loading && !equivalencyCards && (
        <NoDataContainer>
          <NoData componentName={'Equivalency Cards data.'} />
        </NoDataContainer>
      )}
      {loading && <Loader />}
    </>
  );
};

export default Equivalency;
