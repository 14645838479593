import {SelectOption} from '@ekko-earth/dashboard-components/dist/cjs/types/components/Select/Select.interface';
import {API, graphqlOperation} from 'aws-amplify';

import {getOrganisationTeams} from '../../graphql/queries';
import {Teams} from './Filter.interface';

const fetchOptions = async (): Promise<any> => {
  try {
    // @ts-ignore
    const {data} = await API.graphql(graphqlOperation(getOrganisationTeams));
    const {getOrganisationTeams: teams} = data;
    const options: SelectOption[] = teams.teams.map(({id, name}: Teams) => ({
      label: name,
      value: id,
    }));
    return {teams: teams.teams, options};
  } catch (err) {
    console.error({err});
    throw new Error('No teams found.');
  }
};

export {fetchOptions};
