import styled from 'styled-components';

const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr 2px 1fr;
  grid-gap: 10px;
  padding: 16px 12px 0px;
  height: 100%;
`;

const VerticalLine = styled.div`
  border-left: 2px solid #f6f6f6;
  height: 100%;
`;
const NoDataContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  min-height: 200px;
`;
export {Container, VerticalLine, NoDataContainer};
